import { Component } from 'react';
import { Button,  Spinner } from 'react-bootstrap';
import store from 'features/store';
import { MyAccountMenuList,  ToggleSideBar } from '../MyAccountMenuList';
import { getBuyerHeaders,getBuyersUrl } from 'shared/http';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import authService from 'shared/services/authService';
import axios from 'axios';
import { deleteUserValid } from 'shared/settings/apiconfig';
import './Delete-Account.scss'
class DeleteAccount extends Component {
    constructor(props) {
        super(props);
       
        this.state = {
            loading: false,
            showReason: false,
            addReason: true,
            deleteReason: '',
            deleteDescription : '',
            deleteReasonValue: ['I will be back, just taking a break','I want to start over','Created account by mistake','I no longer wish to bid','I prefer to not have a payment method on file to bid','Other'],
            errormessage: '',
        };

    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        if (!this.props.user.id) {
            store.dispatch({ type: 'TOGGLE_SIGNIN_MODAL', payload: { open: true, mode: 'Sign-In' } });
        }
        this.updateDimensions();
        window.addEventListener('resize', this.updateDimensions);
    }
    
    errorMessageCheck = () => {
        let deletestatus = this.props?.user?.deleteStatus ? JSON.parse(this.props.user.deleteStatus) : "";
        const errorCodes = deletestatus ? deletestatus?.ctbids?.errorCode : '';
        
        if(errorCodes.includes('CBBD001')){
            return 'Deletion request cannot be processed at this time, you have bids on active items.'
        }
        else if(errorCodes.includes('CBBD002')){
            return 'Deletion request cannot be processed at this time, you have outstanding unpaid invoices.'
        }
        else if(errorCodes.includes('CBBD003')){
            return 'Deletion request cannot be processed at this time. Invoice generation is not completed for previously won items.'
        }
        else{
            return 'Your CTBids account deletion request is in progress. Please reach out to 844-424-3435 or email CTBIDSHelp@CaringTransitions.com if you have any questions.'
        }
    };

    /* Detect window width */
    updateDimensions = () => {
        ToggleSideBar();
        this.setState({ ...this.state, windowWidth: window.innerWidth });
    };
    cancel = () => {
        this.props.history.push(`/my-account/my-info/personal-info`);
    }
    submit = () => {
        this.setState({
            loading: true,
        })
        const data = {'deleteReason' : this.state.deleteReason,'deleteDescription' : this.state.deleteDescription}
        console.log(data)
        authService.deleteAccount({ "data": data }).then(response => {
            if (response?.status == 200 || response?.data?.status == "success" ) {
                store.dispatch({ type: 'SET_USER', payload: { ...this.props.user, deleteRequest: 1 } });
                this.setState({
                    loading: false
                })
            } else {
                this.setState({
                    loading: false
                })
            }
        }).catch(err => {
            this.setState({
                loading: false
            })
		});
    }
    onChangeDeleteReason = (event) => {
        this.setState({
            addReason: false,
            [event.target.name]: event.target.value,
        })
    }
    errorMessageHandle = (errorCodes) => {
        if(errorCodes.includes('CBBD001')){
            return 'Deletion request cannot be processed at this time, you have bids on active items.'
        }
        else if(errorCodes.includes('CBBD002')){
            return 'Deletion request cannot be processed at this time, you have outstanding unpaid invoices.'
        }
        else if(errorCodes.includes('CBBD003')){
            return 'Deletion request cannot be processed at this time. Invoice generation is not completed for previously won items.'
        }
        else{
            return ''
        }
    }

    deleteUserAccount = () => {
        this.setState({
            loading: true,
        })
        axios.get(getBuyersUrl(`${deleteUserValid}/${this.props.user.id}`), getBuyerHeaders())
        .then(response => {
            if (response?.status == 200 || response?.data?.status == "success" ) {
                this.setState({
                    showReason: true,
                    loading: false,
                })
            }
        }).catch(error => {
            this.setState({
                loading: false,
                errormessage : this.errorMessageHandle(error?.response?.data?.ctbidserrorcode)
            })
            
		});
        
    };

    componentWillUnmount() {
        store.dispatch({ type: 'SET_SIDEBAR', payload: false });
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        return (
            <div className="d-flex " style={{overflow:'auto'}}>
                <div className="delete-account">
                    <div className='container-width'>
                        <div className='text-center font-weight-bold'>
                            {this.props.user.id ?
                                this.props.user.deleteRequest ?
                                    <>
                                        {this.errorMessageCheck()}
                                    </>
                                    :
                                    <>
                                       { this.state.showReason ? 
                                        <>
                                        <h2>Please Select A Reason For Deleting Your Account</h2>

                                                <Form.Group className=" mt-3"  style={{ textAlign: 'left' }}>
                                                    {this.state.deleteReasonValue.map((value, index) => {
                                                        return <Form.Check custom type="radio" style={{ textAlign: 'left' }} id={value} name="deleteReason" className='delete-reason' label={value} value={value} onChange={(event) => this.onChangeDeleteReason(event)} /> 
                                                    })}
                                                </Form.Group>
                                                <label htmlFor="about" className='delete-description' style={{ float: 'left' }}>Tell us more(150 characters max)</label>
                                                <Form.Control as="textarea" row="2" id="about"  style={{ height: "126px" }}  name="deleteDescription"  placeholder=" " maxlength="150" onChange={(event) => this.onChangeDeleteReason(event)}/>
                                                <Button variant="outline-dark btn-white " aria-label='cancel'  onClick={()=>this.cancel()} className='mt-5 mb-5'>Cancel</Button>
                                                <Button variant=""  aria-label='submit' disabled={this.state.addReason || this.state.loading ? true : false} onClick={()=>this.submit()} className='mt-5 mx-2 save-btn mb-5'>Submit {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner> : null}</Button>
                                                
                                        </> :
                                        <>
                                        <h2 className='pb-2'>We are sorry to see you go!</h2>
                                        You are about to submit a request for us to permanently delete your CTBids Account and delete your data.
                                        Requests for account deletion will be handled within 1 business day of request submission.
                                        Once your account is deleted, all of the items accessed through your account will no longer be available to you.
                                        Account deletion is a permanent action and once your account is deleted it cannot be restored.
                                        If you decide later that you want to start bidding again, you will need to create a new account.
                                        {this.state.errormessage ?  <div className='text-danger mt-3'>{this.state.errormessage}</div> : '' }           
                                        <div className="mt-3 d-flex align-items-center justify-content-center">
                                            <Button type="submit" variant=""
                                                disabled={this.state.loading ? true : false || this.state.errormessage}
                                                className='save-btn text-center justify-content-between delete-request-btn'  onClick={() => this.deleteUserAccount()}>Delete Your CTBids Account  {this.state.loading ? <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true"></Spinner> : null}</Button>
                                        </div>

                                        </>}
                                    </>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default connect(state => state)(DeleteAccount)
import { Component, createRef } from 'react';
import { connect } from 'react-redux';
import store from 'features/store';
import { Button, Form, Modal, Col, Row } from 'react-bootstrap';
import { MyAccountMenuList, ToggleSideBar } from '../MyAccountMenuList';
import { fullfillmentlist } from 'shared/settings/apiconfig';
import Datatable from 'shared/datatable';

class ShippedItems extends Component {
    config;
    status = { 'paid': 'text-success', 'unpaid': 'text-danger' };
   
    constructor(props) {
        super(props);
        this.dataTable = createRef();
        this.itemSearch = {
            itemtitle: { field: 'itemtitle', value: '', op: 'LIKE', join: 'AND' },
            invoicenumber: { field: 'invoicenumber', value: '', op: '=', join: 'AND' },
        }
        this.state = {
            pageLoading: false,
            search: {
                ...this.itemSearch
            },
            filterSearch: {},
        }
        this.config = {
            url: `${fullfillmentlist}`,
            params: {},
            columns: [
                { name: 'Item Title', property: 'itemtitle', sort: false, width: '200px' },
                { name: 'Invoice Number', property: 'invoicenumber', sort: false, width: '150px', columnType: 'custom', customColumn: this.custominvoiceview  },
                { name: 'Tracking Number', property: 'trackingnumber', sort: false, width: '150px' },
                { name: 'Carrier', property: 'carrier', sort: false, width: '150px' },
                { name: 'Item Receipt Method', property: 'irm', sort: false, width: '150px', columnType: 'custom', customColumn: this.customirm }
            ],
            field: ["id", "itemid", "buyrid","carrier","trackingnumber", "itemtitle", "irm","invoicenumber","invoiceid"],
            sort: [{ field: 'id', direction: 'desc' }],
            pagination: true,
            service: 'BUYER'
        };
    }

    componentDidMount() {
        store.dispatch({ type: 'SIDEBAR_MENU_LIST', payload: { menuList: MyAccountMenuList(), withSpan: false } });
        this.windowResizeHandle();
        window.addEventListener('resize', this.windowResizeHandle);
    }

    componentDidUpdate() {
        window.addEventListener('resize', this.windowResizeHandle);
    }

    customirm = (value) => {
        const irmObject = JSON.parse(value.irm);
        if (typeof irmObject === 'object' && irmObject !== null) {
            const irm = Object.keys(irmObject).filter(key => irmObject[key] === true);
            return (irm[0].charAt(0).toUpperCase() + irm[0].slice(1));
        }
    }
    custominvoiceview = (values) => {
        return (<span style={{ color: "blue", cursor: "pointer" }} onClick={() => this.invoiceview(values.invoiceid)}>{values['invoicenumber'] ? values['invoicenumber'] : '- - -'}</span>);
    }
    invoiceview = (invid) => {
        this.props.history.push({ pathname :`/my-account/invoice/${invid}/view`, state: {shippeditem : true}})
    }

    enableSearch = (event) => {
        let filter = {};
        filter = {
            itemtitle: { ...this.state.search.itemtitle, value: this.state.search.itemtitle.value ? '*' + this.state.search.itemtitle.value + '*' : '' },
            invoicenumber: { ...this.state.search.invoicenumber, value: this.state.search.invoicenumber.value ? this.state.search.invoicenumber.value : '' },
        }
        this.setState({
            ...this.state,
            filterSearch: filter,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });

    }
    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.enableSearch()
          
        }

    }

    resetState = () => {
        this.setState({
            ...this.state,
            search: this.itemSearch,
            filterSearch: this.itemSearch,
            searchData: true,
        }, () => {
            this.dataTable.current.searchData();
        });
    }

    searchData = (event = null) => {
        if (event) {
            event.preventDefault();
        }
        this.dataTable.current.searchData();
    }
    onInputChange = (event) => {
        let eventValue = event.target.value;
        this.setState({
            ...this.state,
            search: {
                ...this.state.search,
                [event.target.name]: {
                    ...this.state.search[event.target.name],
                    value: eventValue
                }
            }
         
        })
    }

    windowResizeHandle = () => {
        ToggleSideBar();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.windowResizeHandle);
        store.dispatch({ type: 'SET_SIDEBAR', payload: false });
    }

    render() {
        return (
            <div className="invoice-list-main top-border">
                {
                    this.state.pageLoading ?
                        <div className="loader d-flex align-items-center justify-content-center">
                            <img src={`${process.env.PUBLIC_URL}/static/icons/table/loader.gif`} className="loader-icon" alt="loader-icon"></img>
                        </div> :
                        <>
                            <div className=''>
                                <div className="" style={{ backgroundColor: '#BBC7D6' }}>
                                    <Form className="">
                                        <div className="p-3">
                                            <Row >
                                                <Col>
                                                    <label>Title</label>
                                                    <Form.Control onKeyDown={this._handleKeyDown} type="text" value={this.state.search.itemtitle.value} name="itemtitle" onChange={(event) => this.onInputChange(event)} />
                                                </Col>

                                                <Col>
                                                    <label style={{whiteSpace : 'nowrap'}}>Invocie Number</label>
                                                    <Form.Control onKeyDown={this._handleKeyDown} type="text" name="invoicenumber" value={this.state.search.invoicenumber.value} onChange={(event) => this.onInputChange(event)} />
                                                </Col>
                                                <Col>
                                                    <div className="buttonClass" style={{ marginTop: '25px' }}>
                                                        <Button className="clear-button" onClick={() => this.resetState()}>Clear All</Button>
                                                        <Button style={{ backgroundColor: '#00263E', color: 'white' }} onClick={() => this.enableSearch()}>Search</Button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Form>
                                </div>
                            </div>

                            <div className="main-content w-100" style={{ position: "absolute" }}>
                                <div className="">
                                    <Datatable config={{ ...this.config }} search={this.state.searchData ? { search: { ...this.state.filterSearch }, searchData: this.state.searchData } : null} ref={this.dataTable} />
                                </div>
                            </div>
                        </>
                }

            </div>
        );
    }
}

export default connect(state => state)(ShippedItems);